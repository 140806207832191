import { Stack, TextField, Text, Icon } from "@fluentui/react";
import * as microsoftTeams from "@microsoft/teams-js";
import React, { useEffect, useState } from "react";
import { ContextType, TeamsUtils } from "../utils/TeamsUtils";
import './ConfigurationPage.scss';

export interface ConfigurationProps 
{

}

// This page is used to configure the app when being added as a tab to a group chat,
// channel or meeting. This page detects the scenario by checking the context
// received from microsoftTeams.getContext()

export const ConfigurationPage: React.FunctionComponent<ConfigurationProps> = (props) => 
{
    const [ context, setContext ] = useState<microsoftTeams.Context>(null);

    useEffect(() => 
    {
        microsoftTeams.initialize();
        microsoftTeams.getContext((context) => 
        {
            setContext(context);
        });
    }, []);

    useEffect(() => 
    {
        microsoftTeams.settings.registerOnSaveHandler((saveEvent) => 
        {
            const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
            let entityId = 'not set';
            let tabDisplayName = 'Meeting App';
            let pageUrl = '/personal';

            switch(TeamsUtils.contextType(context))
            {
                case ContextType.Meeting: 
                    entityId = `meetingapp-meeting-${context.meetingId}`;
                    tabDisplayName = 'Meeting app (meeting)';
                    pageUrl = '/personal';
                    break;
                case ContextType.Channel: 
                    entityId = `meetingapp-channel-${context.channelId}`;
                    tabDisplayName = 'Meeting app (channel)';
                    pageUrl = '/personal';
                    break;
                case ContextType.GroupChat: 
                    entityId = `meetingapp-group-${context.chatId}`;
                    tabDisplayName = 'Meeting app (group chat)';
                    pageUrl = '/personal'
                    break;
                default:
                    // Should not get here, personal tabs do not show configuration page
                    saveEvent.notifyFailure('Could not detect configuration context');
                    return;
            }

            // List of possible URL placeholder values
            // https://docs.microsoft.com/en-us/microsoftteams/platform/tabs/how-to/access-teams-context
            microsoftTeams.settings.setSettings({
                contentUrl: `${baseUrl}${pageUrl}?entityId={entityId}&loginHint={loginHint}`,
                websiteUrl: `${baseUrl}${pageUrl}?entityId={entityId}&loginHint={loginHint}`,
                entityId: entityId,
                suggestedDisplayName: tabDisplayName,
                removeUrl: `${baseUrl}/remove?entityId={entityId}&loginHint={loginHint}`
            });
    
            saveEvent.notifySuccess(); 
        });
        microsoftTeams.settings.setValidityState(true);
    }, [context]);

    return (
        <div className="page-configuration">
            <Stack className="config-stack">
                <Icon iconName="SkypeCircleCheck" className="config-icon" />
                <Text variant="large" className="config-title">App Configured</Text>
                <Text variant="small">Click Save to continue</Text>
            </Stack>
        </div>
    );
};

export default ConfigurationPage;

// Placeholder URL values
// {entityId}: The ID you supplied for the item in this tab when first configuring the tab.
// {subEntityId}: The ID you supplied when generating a deep link for a specific item within this tab. This must be used to restore to a specific state within an entity; for example, scrolling to or activating a specific piece of content.
// {loginHint}: A value suitable as a login hint for AAD. This is usually the login name of the current user in their home tenant.
// {userPrincipalName}: The User Principal Name of the current user in the current tenant.
// {userObjectId}: The AAD object ID of the current user in the current tenant.
// {theme}: The current user interface (UI) theme such as default, dark, or contrast.
// {groupId}: The ID of the Office 365 group in which the tab resides.
// {tid}: The AAD tenant ID of the current user.
// {locale}: The current locale of the user formatted as languageId-countryId. For example, en-us.