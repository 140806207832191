import React, { useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";

export interface PersonalTab
{

}

export const PersonalTab: React.FunctionComponent<PersonalTab> = (props) => 
{
    const [ context, setContext ] = useState<microsoftTeams.Context>(null);

    useEffect(() => 
    {
        microsoftTeams.initialize();
        microsoftTeams.getContext((ctx) => setContext(ctx));
    }, []);

    return (
        <div>
            <h1>Personal Tab</h1>
            <div>{JSON.stringify(context)}</div>
        </div>
    );
}

export default PersonalTab;