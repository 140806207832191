import React, { useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { Icon, Stack, Text } from "@fluentui/react";
import "./RemovePage.scss";

export interface RemoveProps
{

}

// Teams will redirect to this page when the user removes the tab from a
// group chat, channel or meeting. This page detects the scenario by 
// checking the context received from microsoftTeams.getContext()

export const RemovePage: React.FunctionComponent<RemoveProps> = (props) => 
{
    const [ context, setContext ] = useState<microsoftTeams.Context>(null);

    useEffect(() => 
    {
        microsoftTeams.initialize();

        microsoftTeams.getContext((context) => 
        {
            setContext(context);
        });
    }, []);

    useEffect(() => 
    {
        microsoftTeams.settings.registerOnRemoveHandler((removeEvent) => 
        {
            try
            {
                // Clean up records in our app's database

                removeEvent.notifySuccess();
            }
            catch(ex)
            {
                removeEvent.notifyFailure(ex.message);
            }
        });

        microsoftTeams.settings.setValidityState(true);
    }, [context])

    return (
        <div className="page-remove">
            <Stack className="config-stack">
                <Icon iconName="Cancel" className="config-icon" />
                <Text variant="large" className="config-title">Are you sure you want to remove this tab?</Text>
                <Text variant="small">All data associated with this tab will be deleted.</Text>
            </Stack>
        </div>
    );
}

export default RemovePage;