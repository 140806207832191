const API_GETDEMOS = '/api/GetDemos';

export default class DataService
{
    private static _current: DataService = null;
    public static current()
    {
        return this._current ? this._current : this._current = new DataService();
    }

    public async getDemos(): Promise<any[]>
    {
        const response = await fetch(API_GETDEMOS);
        if(response.ok)
        {
            const demoItems = await response.json();
            return demoItems;
        }
        else
        {
            const error = await response.json();
            throw new Error(error.message);
        }
    }
}