import * as microsoftTeams from "@microsoft/teams-js";

export enum ContextType
{
    Meeting = 'meeting',
    Channel = 'channel',
    GroupChat = 'groupChat',
    PersonalTab = 'personalTab'
}

export class TeamsUtils
{
    public static contextType(context: microsoftTeams.Context)
    {
        if(context.meetingId)
            return ContextType.Meeting
        else if(context.channelId)
            return ContextType.Channel;
        else if(context.chatId)
            return ContextType.GroupChat
        else
            return ContextType.PersonalTab
    }
}