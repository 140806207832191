import './App.scss';
import React from 'react';
import DataService from './data/DataService';
import { IDemo } from './dto/IDemo';

export interface AppProps
{

}

export interface AppState
{
    demos: IDemo[];
}

export default class App extends React.Component<AppProps, AppState>
{
    constructor(props: AppProps)
    {
        super(props);
        this.state = {
            demos: []
        };
    }

    public async componentDidMount()
    {
        const demos = await DataService.current().getDemos();
        this.setState({ demos });
    }

    public render()
    {
        return (
            <div className="App">
                <h1>Demo Static Web App</h1>
                <div>Items from SharePoint:</div>
                <ul>
                    {this.state.demos.map(d => <li>{d.Title}</li>)}
                </ul>
            </div>
        );
    }
}
