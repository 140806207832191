import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './index.scss';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import { initializeIcons, ThemeProvider } from '@fluentui/react';
import ConfigurationPage from './pages/ConfigurationPage';
import RemovePage from './pages/RemovePage';
import PersonalTab from './pages/PersonalTab';

initializeIcons();

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Switch>
                <Route path="/config" component={ConfigurationPage} />
                <Route path="/remove" component={RemovePage} />
                <Route path="/personal" component={PersonalTab} />
                <Route path="/" component={App} />
            </Switch>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
